<template>
  <div>
    <skeleton-table v-if="initialize" :rows="10" :columns="fields.length" :buttons="4" :search="true" />

    <no-records-found
      v-if="showNotFound"
      text="No contact groups are added yet"
      btnOneToggle="sidebar-add-group"
    >
      <template #btnOne>
        <feather-icon icon="PlusCircleIcon" class="mr-50" />
        <span class="align-middle">Add Group</span>
      </template>

    </no-records-found>
    
    <b-card v-else no-body class="action-buttons" :class="{'d-none': mainCardInvisiblity}">
      <b-row class="mb-1 mx-1">
        <b-col md="6" xl="8">
          <div class="inline-spacing">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              class="mr-50 mt-2"
              v-b-toggle.sidebar-add-group
            >
              <feather-icon icon="PlusCircleIcon" class="mr-50" />
              <span class="align-middle">Add Group</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="
                !selectedGroups.length ? 'outline-secondary' : 'outline-primary'
              "
              v-b-toggle.sidebar-compose-message-group
              class="mr-50 mt-2"
              :disabled="!selectedGroups.length"
            >
              <feather-icon icon="SendIcon" class="mr-50" />
              <span class="align-middle">Send Message</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="
                !selectedGroups.length ? 'outline-secondary' : 'outline-primary'
              "
              class="mr-50 mt-2"
              v-b-modal.delete-bulk-group
              :disabled="!selectedGroups.length"
            >
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span class="align-middle">Delete</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mr-50 mt-2"
              @click="downloadAllGroups"
              :disabled="downloadIsLoading"
              :class="{ disabled: downloadIsLoading }"
            >
              <b-spinner small v-if="downloadIsLoading" />
              &nbsp;
              <feather-icon icon="DownloadIcon" class="mr-50" />
              <span class="align-middle">Download</span>
            </b-button>
          </div>
        </b-col>

        <b-col md="6" xl="4" class="mt-2">
          <!-- basic -->
          <b-form-group>
            <b-input-group>

              <b-form-input v-model.trim="search" placeholder="Search for groups" />

              <b-input-group-append>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon rounded-circle ml-1"
                  @click="groupRefresh"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>

            </b-input-group>

          </b-form-group>

          
        </b-col>
      </b-row>

      <b-table
        show-empty
        empty-text="No records found from your search"
        empty-filtered-text="No records found from your search"
        hover
        striped
        :busy.sync="isBusy"
        :current-page="currentPage"
        :per-page="perPage"
        responsive
        :items="groups"
        :fields="fields"
        ref="groupsTable"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #head(ID)>
          <b-form-checkbox
            v-model="allSelected"
            @change="selectAll"
            :value="true"
            :unchecked-value="false"
          >
          </b-form-checkbox>
        </template>

        <template #cell(ID)="data">
          <b-form-checkbox
            v-model="selectedGroups"
            :value="data.value"
          >
          </b-form-checkbox>
        </template>

        <template #cell(contacts_count)="data">
          {{Number(data.value).toLocaleString()}}
        </template>

        <template #cell(actions)="row">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-info"
            class="btn-icon rounded-circle ml-1 ml-sm-0"
            v-b-tooltip.hover title="Edit Group"
            @click="editGroup(row.item.ID)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="btn-icon rounded-circle ml-1 mt-1 mt-sm-0"
            v-b-tooltip.hover title="Send Message"
            @click="SendGroupMessage(row.item.ID)"
            :disabled="row.item.contacts_count == 0"
          >
            <feather-icon icon="SendIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-success"
            class="btn-icon rounded-circle ml-1 mt-1 mt-sm-0"
            v-b-tooltip.hover title="View Group Contacts"
            @click="viewGroupContacts(row.item.ID, row.item.name)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-danger"
            class="btn-icon rounded-circle ml-1 mt-1 mt-sm-0"
            v-b-modal.delete-group
            v-b-tooltip.hover title="Delete Group"
            @click="deleteGroup(row.item.ID)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </template>

        <!-- <template #cell(actions)="row">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>

            <b-dropdown-item
              @click="editGroup(row.item.ID)"
            >
              <feather-icon icon="EditIcon" class="mr-50" />
              <span>Edit Group</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="SendGroupMessage(row.item.ID)"
              v-if="row.item.contacts_count > 0"
            >
              <feather-icon icon="SendIcon" class="mr-50" />
              <span>Send Message</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="viewGroupContacts(row.item.ID, row.item.name)"
              v-if="row.item.contacts_count > 0"
            >
              <feather-icon icon="EyeIcon" class="mr-50" />
              <span>View Group Contacts</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-b-modal.delete-group
              @click="deleteGroup(row.item.ID)"
            >
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span>Delete Contact</span>
            </b-dropdown-item>
          </b-dropdown>
        </template> -->

      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="lg"
        v-if="!noRecordsFound"
      ></b-pagination>
      
    </b-card>

    <sidebar-add-group @groups-refresh="groupRefresh" />
    <sidebar-edit-group :id="groupId" @groups-refresh="groupRefresh"  @reset-id="resetGroupId"/>
    <delete-single-group :id="groupId" @groups-refresh="groupRefresh"/>
    <delete-bulk-group :ids="selectedGroups" @groups-refresh="groupRefresh"/>
    <sidebar-compose-message-group :groupIds="selectedGroups"></sidebar-compose-message-group>
  </div>
</template>

<script>
import {
  BPagination,
  BTable,
  BButton,
  BSpinner,
  BCard,
  BFormInput,
  BFormCheckbox,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCol,
  BRow,
  VBToggle,
  VBModal,
  VBTooltip,
  
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import SidebarAddGroup from "./components/SidebarAddGroup.vue";
import SidebarEditGroup from "./components/SidebarEditGroup.vue";
import DeleteSingleGroup from "./components/DeleteSingleGroup.vue";
import DeleteBulkGroup from "./components/DeleteBulkGroup.vue";
import SidebarComposeMessageGroup from "./components/SidebarComposeMessageGroup.vue";
import NoRecordsFound from "@/views/pages/no-records-found/NoRecordsFound.vue";
import SkeletonTable from "@/views/pages/shimmers/SkeletonTable.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  components: {
    BTable,
    BPagination,
    BCard,
    BButton,
    BSpinner,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BCol,
    BRow,
    SidebarAddGroup,
    SidebarEditGroup,
    DeleteSingleGroup,
    DeleteBulkGroup,
    SidebarComposeMessageGroup,
    NoRecordsFound,
    SkeletonTable,
  },
  data() {
    return {
      fields: [
        {
          key: "ID",
          label: "SELECTION",
        },
        {
          key: "name",
          label: "GROUP NAME",
        },
        {
          key: "description",
          label: "GROUP DESCRIPTION",
        },
        {
          key: "contacts_count",
          label: "CONTACTS",
        },
        {
          key: "format_created_at",
          label: "DATE ADDED",
        },
        {
          key: "actions",
          label: "ACTIONS",
        },
      ],
      selectedGroups: [],
      allGroupsId: [],
      allSelected: false,
      search: "",
      groupId:false,
      downloadIsLoading:false,
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      initialize: true,
      showNotFound: false,
      noRecordsFound: false,
      mainCardInvisiblity:true,
    };
  },
  methods: {
    groups(ctx, callback) {
      let params
      if (this.search) {
        params = "?page=" + ctx.currentPage + "&search=" + this.search;
      } else {
        params = "?page=" + ctx.currentPage;
      }
      this.$http
        .get("/contact-group/" + params)
        .then((response) => {
          this.totalRows = response.data.total_pages * 10;
          // Pluck the array of items off our axios response
          const items = response.data.data;
          if (items.length == 0) {
            this.noRecordsFound = true;

            if (this.initialize) {
              this.showNotFound = true;
            }
          } else {
            this.noRecordsFound = false;
          }
          this.initialize = false;
          this.isBusy = false;
          this.allSelected = false;
          this.allGroupsId = [];
          this.selectedGroups = [];
          for (let group of items) {
            this.allGroupsId.push(group.ID);
          }
          this.mainCardInvisiblity = false;
          callback(items);
        })
        .catch((error) => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          if (this.initialize) {
            this.showNotFound = true;
          }
          this.noRecordsFound = true;
          this.initialize = false;
          this.mainCardInvisiblity = false;
          callback([]);
          this.isBusy = false;
        });
      return null;
    },
    selectAll() {
      if (this.allSelected) {
        this.selectedGroups = this.allGroupsId;
      } else {
        this.selectedGroups = [];
      }
    },
    groupRefresh() {
      this.showNotFound = false;
      this.groupId = false;
      this.$refs.groupsTable.refresh();
    },
    editGroup(id) {
      this.groupId = id;
      this.$root.$emit('bv::toggle::collapse', 'sidebar-edit-group')
    },
    deleteGroup(id) {
      this.groupId = id;
    },
    SendGroupMessage(id) {
      this.$root.$emit('bv::toggle::collapse', 'sidebar-compose-message-group', id)
    },
    viewGroupContacts(id, name){
      this.$router
        .push({ 
          name: 'client-group-contacts',
          params: { groupId: id, name: name }
        })
    },
    resetGroupId(){
      this.groupId = false;
    },
    downloadAllGroups(){
      this.downloadIsLoading = true;
      this.$http
        .get("/contact-group/download",{
          responseType: 'blob',
        })
        .then( response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'all-groups-download.xlsx');
          document.body.appendChild(link);
          link.click();
          setTimeout(() =>{ 
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "All Contact Groups Downloaded",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.downloadIsLoading = false;
          }, 1000);
        })
        .catch(error => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title:err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          this.downloadIsLoading = false;
        })
    }
  },
};
</script>
