<template>
  <b-modal
    id="delete-group"
    centered
    title="Delete Group"
    ok-title="Delete"
    ok-variant="danger"
    cancel-title="Cancel"
    cancel-variant="secondary"
    @ok="deleteGroup"
  >
    <b-card-text> Are you sure you want to delete this contact group ? </b-card-text>
  </b-modal>
</template>

<script>
import { BModal, BCardText } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BModal,
    BCardText,
  },
  props: ["id"],
  emits: ["groups-refresh"],
  methods:{
    deleteGroup(){
      this.$http
        .get("/contact-group/delete/"+ this.id)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: response.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$emit('groups-refresh')
        })
    }
  }
};
</script>
