<template>
  <b-modal
    id="delete-bulk-group"
    centered
    title="Delete Groups"
    ok-title="Delete"
    ok-variant="danger"
    cancel-title="Cancel"
    cancel-variant="secondary"
    @ok="DeleteBulkGroup"
  >
    <b-card-text> Are you sure you want to delete all the selected contact groups ? </b-card-text>
  </b-modal>
</template>

<script>
import { BModal, BCardText } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BModal,
    BCardText,
  },
  props: ["ids"],
  emits: ["groups-refresh"],
  methods:{
    DeleteBulkGroup(){
      this.$http
        .post("/contact-group/delete",{
          ids:this.ids,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: response.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$emit('groups-refresh')
        })
        .catch(error => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title:err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
        })
    }
  }
};
</script>
