<template>
  <b-sidebar
    id="sidebar-add-group"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Add Group</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer ref="groupAddFormValidator" #default="{ invalid }">
        <b-form class="p-2" @submit.prevent="addGroup" ref="groupAddForm">

           <b-form-group label-for="name" label="Group Name">
              <validation-provider
                #default="{ errors }"
                name="Group Name"
                vid="name"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="name"
                  name="name"
                  placeholder="Group Name"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label-for="description" label="Group Description">
              <validation-provider
                name="Group Description"
                vid="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="description"
                  name="description"
                  placeholder="Group Description"
                  rows="6"
                  max-rows="6"
                ></b-form-textarea>
              </validation-provider>
            </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex align-items-center justify-content-between">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="invalid || formIsLoading"
              :class="{ disabled: invalid }"
            >
              <b-spinner small v-if="formIsLoading" />
              &nbsp;
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BSpinner,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  emits: ["groups-refresh"],
  data() {
    return {
      name: "",
      description:"",
      formIsLoading:false,
      required,
    };
  },
  methods: {
    addGroup() {
      this.formIsLoading = true;
      this.$refs.groupAddFormValidator.validate().then((success) => {
        if (success) {
          this.$http.post('/contact-group/create',{
            name: this.name,
            description: this.description,
          })
          .then(response => {
            this.formIsLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: response.data.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.$emit('groups-refresh')
            this.$root.$emit('bv::toggle::collapse', 'sidebar-add-group')
            this.$nextTick(() => {
              this.$refs.groupAddFormValidator.reset();
            });
            this.$refs.groupAddForm.reset();
          })
          .catch(error => {
              for (let err of error.response.data.error) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title:err,
                    icon: "AlertCircleIcon",
                    variant: "danger",
                  },
                });
              }
              this.formIsLoading = false;
          })
        }
      });
    },
  }
};
</script>